import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { RoutePath } from '../enums/Routes'
import { isNonAuthPage } from './urls'

export type ErrorResultType = { type: string; error: AxiosError }

const HTTP_STATUS = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  405: 'METHOD_NOT_ALLOWED',
  422: 'UNPROCESSABLE ENTITY',
  500: 'INTERNAL_ERROR',
}

type validationErrorType = {
  propertyName: string
  errorMessage: string
}

type errorType = {
  requestId: string
  error: string
  errorDescription: string
  validationErrors: validationErrorType[]
  result: any
}

export const handleFieldErrors = (error: errorType): void => {
  if (error.validationErrors) {
    error.validationErrors.forEach((item: any) => {
      if (message) {
        message.error(item.errorMessage)
      }
    })
  } else {
    if (!error.errorDescription) {
      message.error(error)
    } else {
      if (message) {
        message.error(error.errorDescription)
      }
    }
  }
}

export const handleUnauthorized = (error: AxiosError): void => {
  const { data } = error.response as AxiosResponse
  if (message) {
    message.error(data.message)
  }

  window.location.href = RoutePath.AUTH_LOGIN
}

export const normalizeErrors = (
  error: AxiosError
): Promise<ErrorResultType> => {
  const status = error.response?.status || 500
  const errorResult = { type: HTTP_STATUS['500'], error: error }
  switch (status) {
    case 400: {
      errorResult.type = HTTP_STATUS['400']
      if (error.response?.data instanceof Blob) {
        const reader: any = new FileReader();
        reader.readAsText(error.response?.data);
        reader.onload = () => {
          const jsonData = JSON.parse(reader.result)
          message.error(jsonData?.errorDescription)
        }
      } 
      // else if (error.response?.data.errorDescription === "Bordro görüntüleyebilmek için oturum sms doğrulaması gerçekleştirmelisiniz.") {
      //   message.error(error.response?.data.errorDescription)
      //   setTimeout(() => {
      //     window.location.href = RoutePath.PAYROLLS
      //   }, 500)
      // }
       else {
        handleFieldErrors(error.response?.data?.errorDescription)
      }
      break
    }
    case 401: {
      errorResult.type = HTTP_STATUS['401']
      if (!isNonAuthPage(window.location.pathname)) {
        window.location.href = RoutePath.AUTH_LOGOUT
      }
      break
    }
    case 403: {
      errorResult.type = HTTP_STATUS['403']
      if (message) {
        if (error.response?.data?.errorDescription === "Bu işlem için yetkiniz bulunmamaktadır.") {
          message.error(error.response?.data?.errorDescription)
          window.location.href = '/welcoming-page'
        } else {
          message.error(error.response?.data?.errorDescription)
        }
      }
      break
    }
    case 404: {
      errorResult.type = HTTP_STATUS['404']
      if (message) {
        message.error(error.response?.data?.errorDescription)
      }
      break
    }
    case 422: {
      errorResult.type = HTTP_STATUS['422']
      if (message) {
        message.error(error.response?.data?.errorDescription)
      }
      break
    }
    case 500: {
      errorResult.type = HTTP_STATUS['500']
      if (message) {
        message.error(error.response?.data?.errorDescription ?? 'İşleminiz şu anda gerçekleştirilemiyor.')
      }
      break
    }
  }
  return Promise.reject(errorResult)
}
