import { ClearOutlined, DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { RoutePath } from '../../enums/Routes'
import { currentUserState } from '../../store/user'
import styles from './AvatarMenu.module.scss'
import API from '../../utils/api'

const AvatarMenu = (): JSX.Element => {
  const history = useHistory()
  const currentUser = useRecoilValue(currentUserState)
  const { t } = useTranslation()

  const clearCache = () => {
    API.get('/Maintenance/ClearCache', {
    }).then(() => {
      message.success(t('text.success_message_edit'))
      window.location.reload()
    })
  }

  const menu = (
    <Menu>
      {/* <Menu.Item icon={<UserOutlined />}>{t('text.profile')}</Menu.Item> */}
      <Menu.Item icon={<ClearOutlined />} onClick={clearCache}>{t('Önbelleği Temizle')}</Menu.Item>
      <Menu.Item
        danger
        icon={<LogoutOutlined />}
        onClick={() => history.push(RoutePath.AUTH_LOGOUT)}
      >
        {t('text.logout')}
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <div className="d-flex align-items-center">
        <Avatar icon={<UserOutlined />} className={styles.avatar} style={{marginLeft: '20px'}}/>
        <span className={styles.userName}>
          {currentUser?.userName} <DownOutlined />
        </span>
      </div>
    </Dropdown>
  )
}

export default AvatarMenu
