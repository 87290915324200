import btn from './btn.json'
import label from './label.json'
import text from './text.json'
import title from './title.json'
import validation from './validation.json'

export default {
  translation: {
    label,
    btn,
    title,
    text,
    validation,
  },
}
