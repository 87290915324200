import { AxiosResponse } from 'axios'
import { ReactNode, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { RoutePath } from '../../enums/Routes'
import { currentUserState } from '../../store/user'
import { UserMeEntity } from '../../types/entities/user'
import API from '../../utils/api'
import { isNonAuthPage } from '../../utils/urls'
import i18n from '../../i18n'
import { Modal } from 'antd'
import { t } from 'i18next'
import { BaseResponse } from '../../types/common/baseResponse'
import { AdminRole } from '../../enums/RoleEnum'

type AuthGuardProps = {
  children: ReactNode
}

const AuthGuard = ({ children }: AuthGuardProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const token = localStorage.getItem('token')
  const setCurrentUser = useSetRecoilState(currentUserState)
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const currentLang = localStorage.getItem('lang') || 'tr'
  const [confirmModal, setConfirmModal] = useState(false)
  const lang = searchParams.get('setLanguage');
  const [user, setUser] = useState<any>({})

  useEffect(() => {
    if (!isNonAuthPage(history.location.pathname)) {
      API.get('/Authentication/Me')
        .then(({ data }: AxiosResponse<BaseResponse<UserMeEntity>>) => {
          if (data.result) {
            setCurrentUser(data.result);
            setUser(data.result)
          }
        })
        .catch(() => {
          setCurrentUser(null);
          history.push(RoutePath.AUTH_LOGIN);
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user?.role === AdminRole.HR) {
      if (
        location.pathname.includes('/auditLog') ||
        location.pathname.includes('/editors') ||
        location.pathname.includes('/mediaProperty')
      ) {
        history.push('/welcoming-page');
      }
    } else if (user?.role === AdminRole.Editor) {
      if (
        location.pathname.includes('/auditLog') ||
        location.pathname.includes('/editors') ||
        location.pathname.includes('/mediaProperty') ||
        location.pathname.includes('/leaves') ||
        location.pathname.includes('/national-holidays') ||
        location.pathname.includes('/payroll') ||
        location.pathname.includes('/pending-leaves')
      ) {
        history.push('/welcoming-page');
      }
    }
  }, [user])

  useEffect(() => {
    if (!token && !isNonAuthPage(history.location.pathname)) {
      history.push(RoutePath.AUTH_LOGIN)
    } else {
      if (lang) {
        if (currentLang != lang) {
          setConfirmModal(true)
        }
      }
    }
  }, [location.pathname])

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');

    if (!storedLang) {
      localStorage.setItem('lang', 'tr');
    }
  }, []);

  return <><Modal
    visible={confirmModal}
    onCancel={() => setConfirmModal(false)}
    title={t('title.warning')}
    onOk={() => {
      // eslint-disable-next-line
      i18n.changeLanguage(lang!)
      // eslint-disable-next-line
      localStorage.setItem('lang', lang!)
      window.location.reload()
    }} >
    <p>{"Şu anda uygulamanın " + (currentLang == "tr" ? "Türkçe" : "İngilizce") + " versiyonunu görüntülüyorsunuz. Ancak görüntülemek istediğiniz içerik " + (lang == "tr" ? "Türkçe" : " İngilizce") + " versiyonunda olan bir içeriktir."}</p>
    <p>{"Hali hazırda devam eden işlemleriniz var ise (İçerik girişi, Onaylama vb.) onları tamamlamalısınız, aksi takdirde ilgili değişilikler de bu dil değişiminden etkilenebilir."}</p>
    <h3>{"İçeriği görüntülemek için dil değişikliğini onaylıyor musunuz ? "}</h3>
  </Modal>{children}</>
}
export default AuthGuard
