import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import trFlag from '../../assets/img/turkey.png'
import enFlag from '../../assets/img/united-kingdom.png'
import { RoutePath } from '../../enums/Routes'
import i18n from '../../i18n'
import styles from './LanguageMenu.module.scss'

const LanguageMenu = (): JSX.Element => {
  const history = useHistory()
  const currentLang = localStorage.getItem('lang') || 'tr'
  const { t } = useTranslation()
  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    history.push(RoutePath.SITEMAP)
    window.location.reload()
  }
  const menu = (
    <Menu>
      <Menu.Item
        icon={<img src={trFlag} width={18} alt="flag" />}
        onClick={() => setLanguage('tr')}
      >
        {t('text.turkish')}
      </Menu.Item>
      <Menu.Item
        icon={<img src={enFlag} width={18} alt="flag" />}
        onClick={() => setLanguage('en')}
      >
        {t('text.english')}
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} placement="bottomLeft" className={styles.language}>
      <div className="d-flex align-items-center">
        <span>
          <img
            src={currentLang === 'tr' ? trFlag : enFlag}
            width={33}
            alt="flag"
            className={styles.flag}
          />
          <DownOutlined />
        </span>
      </div>
    </Dropdown>
  )
}

export default LanguageMenu
