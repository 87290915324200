import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import tr from './tr'
import en from './en'

const resources = {
  tr,
  en,
}

localStorage.setItem('lang', localStorage.getItem('lang') || 'tr')

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'tr',
  fallbackLng: 'tr',
  supportedLngs: ['tr', 'en'],
  returnEmptyString: false,
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
} as InitOptions)

export default i18n
