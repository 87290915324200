export enum RoutePath {
  HOME = '/',

  WELCOMING_PAGE = '/welcoming-page',

  AUTH = '/auth',
  AUTH_LOGIN = '/auth/login',
  AUTH_LOGOUT = '/auth/logout',

  SITEMAP = '/sitemap',
  SITEMAP_DETAIL = '/sitemap/:id',
  SITEMAP_CREATE = '/sitemap/create/:id',
  SITEMAP_EDIT = '/sitemap/edit/:id',
  SITEMAP_SINGLECONTENTPAGE = '/sitemap/single-content/:id',
  SITEMAP_MULTICONTENTPAGE = '/sitemap/multi-content/:id',
  SITEMAP_MULTICONTENTCREATEPAGE = '/sitemap/multi-content-create/:type/:id',

  PENDING_LEAVE_LIST = '/pending-leaves',

  BANNERS = '/banners',
  BANNERS_CREATED = '/banners/create',
  BANNERS_EDIT = '/banners/edit/:id',
  BANNERS_DETAIL = '/banners/:id',

  APP_SETTINGS_LIST = '/app-settings',
  APP_SETTINGS_CREATE = '/app-settings/create',
  APP_SETTINGS_EDIT = '/app-settings/edit/:id',

  ADVANCE = '/advance',
  // ADVANCE_CREATE = '/advance/create',
  // ADVANCE_EDIT = '/advance/edit/:id',

  ADMINISTRATIVETASK_LIST = '/administrative-task',
  ADMINISTRATIVETASK_DETAIL = '/administrative-task/edit/:id',

  AWARDS = '/awards',
  AWARDS_CREATED = '/awards/create',
  AWARDS_EDIT = '/awards/edit/:id',
  AWARDS_UPLOAD = '/awards/upload',

  LOCATIONS = '/locations',
  LOCATION_CREATE = '/locations/create',
  LOCATION_EDIT = '/locations/edit/:id',

  CMS = '/cms',
  CMS_CREATE = '/cms/create',
  CMS_EDIT = '/cms/edit/:id',

  AWARD_TRANSACTION_LIST = '/award-transactions',

  NATIONAL_HOLIDAY = '/national-holidays',
  NATIONAL_HOLIDAY_CREATED = '/national-holidays/create',
  NATIONAL_HOLIDAY_EDIT = '/national-holidays/edit/:id',

  PAYROLLS = '/payroll',
  PAYROLL_CREATE = '/payroll/create',
  PAYROLL_EDIT = '/payroll/edit/:id',

  AWARD_BUDGETS = '/award-budgets',
  AWARD_BUDGETS_CREATE = '/award-budgets/create',
  AWARD_BUDGETS_EDIT = '/award-budgets/edit/:id',

  COMMENTS = '/comments',
  COMMENTS_CREATED = '/comments/create',

  COMPANIES = '/companies',
  COMPANIES_CREATED = '/companies/create',
  COMPANIES_EDIT = '/companies/edit/:id',

  COMPANY_HEADQUARTER_LIST = '/companies/headquarters',
  COMPANY_HEADQUARTER_EDIT = '/companies/headquarter/:id',

  COMPANIES_UNIT_LIST = '/companies/units',
  COMPANIES_UNIT_EDIT = '/companies/unit/:id',

  COMPANIES_USERTYPE_LIST = '/companies/usertypes',
  COMPANIES_USERTYPE_EDIT = '/companies/usertype/:id',

  SURVEYS = '/surveys',
  SURVEYS_CREATE = '/surveys/create',
  SURVEYS_EDIT = '/surveys/edit/:id',
  SURVEYS_DETAIL = '/surveys/:id',
  SURVEYS_MULTI_RESULTS = '/surveys/multi-results/:id',

  REDIRECTION = '/redirection',
  REDIRECTION_CREATE = '/redirection/create',
  REDIRECTION_EDIT = '/redirection/edit/:id',
  REDIRECTION_DETAIL = '/redirection/:id',

  EDITORS = '/editors',
  EDITORS_CREATE = '/editors/create',
  EDITORS_EDIT = '/editors/edit/:id',
  EDITORS_DETAIL = '/editors/:id',

  USER = '/user',
  USER_CREATE = '/user/create',
  USER_EDIT = '/user/edit/:id',
  USER_DETAIL = '/user/:id',

  LEAVES = '/leaves',
  LEAVES_CREATED = '/leaves/create/:id',
  LEAVES_EDIT = '/leaves/edit/:id',

  MEDIA = '/media',
  MEDIA_CREATE = '/media/create',
  MEDIA_EDIT = '/media/edit/:id',
  MEDIA_DETAIL = '/media/:id',


  SHAREDS = '/shareds',
  SHAREDS_CREATED = '/shareds/create',
  SHAREDS_EDIT = '/shareds/edit/:id',
  SHAREDS_DETAIL = '/shareds/:id',


  MEDIUM_MANAGEMENT = '/mediummanagement',
  MEDIUM_MANAGEMENT_CREATE = '/mediummanagement/create',
  MEDIUM_MANAGEMENT_EDIT = '/mediummanagement/edit/:id',
  MEDIUM_MANAGEMENT_DETAIL = '/mediummanagement/:id',

  MEDIA_PROPERTY = '/mediaProperty',
  MEDIA_PROPERTY_CREATED = '/mediaProperty/create',
  MEDIA_PROPERTY_EDIT = '/mediaProperty/edit/:id',
  MEDIA_PROPERTY_DETAIL = '/mediaProperty/:id',

  LOG = '/log',
  LOG_DETAIL = '/log/:id',

  AUDIT_LOG = '/auditLog',
  AUDIT_LOG_DETAIL = '/auditLog/:id'
}

