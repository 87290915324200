import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import AuthGuard from './components/AuthGuard/AuthGuard'
import Loading from './components/Loading/Loading'
import MasterPage from './components/MasterPage/MasterPage'
import { RoutePath } from './enums/Routes'
import './style.css'
import { currentUserState } from './store/user'

const Pages = {
  AUTH: lazy(() => import('./pages/Auth')),
  SITEMAP: lazy(() => import('./pages/Sitemap')),
  COMPANY: lazy(() => import('./pages/Company')),
  BANNERS: lazy(() => import('./pages/Banners')),
  AWARD_BUDGET: lazy(() => import('./pages/AwardBudget')),
  NATIONAL_HOLIDAYS: lazy(() => import('./pages/NationalHoliday')),
  PAYROLLS: lazy(() => import('./pages/Payroll')),
  SURVEYS: lazy(() => import('./pages/Suverys')),
  EDITORS: lazy(() => import('./pages/Editors')),
  USERS: lazy(() => import('./pages/Users')),
  LEAVES: lazy(() => import('./pages/Leaves')),
  REDIRECTION: lazy(() => import('./pages/Redirection')),
  MEDIA: lazy(() => import('./pages/Media')),
  NOMATCH: lazy(() => import('./pages/NoMatch')),
  SHAREDS: lazy(() => import('./pages/Shareds')),
  AWARDS: lazy(() => import('./pages/Awards')),
  AWARDTRANSACTIONS: lazy(() => import('./pages/AwardTransactions')),
  MEDIUMMANAGEMENTS: lazy(() => import('./pages/MediumManagement')),
  MEDIAPROPERTIES: lazy(() => import('./pages/MediaProperties')),
  LOGS: lazy(() => import('./pages/Log')),
  AUTDITLOGS: lazy(() => import('./pages/AuditLog')),
  CMS: lazy(() => import('./pages/CMS')),
  COMMENTS: lazy(() => import('./pages/SustainabilityUserComment/')),
  PENDINGLEAVES: lazy(() => import('./pages/PendingLeaves')),
  LOCATIONS: lazy(() => import("./pages/Locations")),
  ADVANCE: lazy(() => import("./pages/Advance")),
  ADMINISTRATIVETASK: lazy(() => import("./pages/AdministrativeTask")),
  APPSETTINGS: lazy(() => import("./pages/AppSettings")),
  WELCOMINGPAGE: lazy(() => import("./pages/WelcomingPage")),
}

const App = (): JSX.Element => {
  const lang = localStorage.getItem('lang');
  const currentUser = useRecoilValue(currentUserState)

  const returnFunc = () => {
    if (lang === 'en') {
      return (
        <Switch>
          <Route exact path={RoutePath.HOME}>
            <Redirect to={RoutePath.SITEMAP} />
          </Route>
          <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
          <Route path={RoutePath.AUTH} component={Pages.AUTH} />
          <Route path={RoutePath.SITEMAP} component={Pages.SITEMAP} />
          <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
          <Route path={RoutePath.SURVEYS} component={Pages.SURVEYS} />
          <Route path="*" component={Pages.NOMATCH} />
        </Switch>
      )
    } else {
      switch (currentUser?.package) {
        case "Package1":
          return <Switch>
            <Route path={RoutePath.AUTH} component={Pages.AUTH} />
            <Route exact path={RoutePath.HOME}>
              <Redirect to={RoutePath.SITEMAP} />
            </Route>
            <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
            <Route path={RoutePath.SITEMAP} component={Pages.SITEMAP} />
            <Route path={RoutePath.LOCATIONS} component={Pages.LOCATIONS} />
            <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
            <Route path={RoutePath.SURVEYS} component={Pages.SURVEYS} />
            <Route path={RoutePath.REDIRECTION} component={Pages.REDIRECTION} />
            <Route path={RoutePath.USER} component={Pages.USERS} />
            <Route path={RoutePath.EDITORS} component={Pages.EDITORS} />
            <Route path={RoutePath.MEDIA} component={Pages.MEDIA} />
            <Route path={RoutePath.MEDIUM_MANAGEMENT} component={Pages.MEDIUMMANAGEMENTS} />
            <Route path={RoutePath.MEDIA_PROPERTY} component={Pages.MEDIAPROPERTIES} />
            <Route path={RoutePath.LOG} component={Pages.LOGS} />
            <Route path={RoutePath.AUDIT_LOG} component={Pages.AUTDITLOGS} />
            <Route path={RoutePath.APP_SETTINGS_LIST} component={Pages.APPSETTINGS} />
            <Route path="*" component={Pages.NOMATCH} />
          </Switch>
        case "Package12":
          return <Switch>
            <Route path={RoutePath.AUTH} component={Pages.AUTH} />
            <Route exact path={RoutePath.HOME}>
              <Redirect to={RoutePath.SITEMAP} />
            </Route>
            <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
            <Route path={RoutePath.SITEMAP} component={Pages.SITEMAP} />
            <Route path={RoutePath.LOCATIONS} component={Pages.LOCATIONS} />
            <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
            <Route path={RoutePath.SURVEYS} component={Pages.SURVEYS} />
            <Route path={RoutePath.REDIRECTION} component={Pages.REDIRECTION} />
            <Route path={RoutePath.USER} component={Pages.USERS} />
            <Route path={RoutePath.EDITORS} component={Pages.EDITORS} />
            <Route path={RoutePath.MEDIA} component={Pages.MEDIA} />
            <Route path={RoutePath.MEDIUM_MANAGEMENT} component={Pages.MEDIUMMANAGEMENTS} />
            <Route path={RoutePath.MEDIA_PROPERTY} component={Pages.MEDIAPROPERTIES} />
            <Route path={RoutePath.LOG} component={Pages.LOGS} />
            <Route path={RoutePath.AUDIT_LOG} component={Pages.AUTDITLOGS} />
            <Route path={RoutePath.NATIONAL_HOLIDAY} component={Pages.NATIONAL_HOLIDAYS} />
            <Route path={RoutePath.COMMENTS} component={Pages.COMMENTS} />
            <Route path={RoutePath.LEAVES} component={Pages.LEAVES} />
            <Route path={RoutePath.PENDING_LEAVE_LIST} component={Pages.PENDINGLEAVES} />
            <Route path={RoutePath.PAYROLLS} component={Pages.PAYROLLS} />
            <Route path={RoutePath.ADMINISTRATIVETASK_LIST} component={Pages.ADMINISTRATIVETASK} />
            <Route path={RoutePath.APP_SETTINGS_LIST} component={Pages.APPSETTINGS} />
            <Route path={RoutePath.ADVANCE} component={Pages.ADVANCE} />
            <Route path="*" component={Pages.NOMATCH} />
          </Switch>
        case "Package13":
          return <Switch>
            <Route path={RoutePath.AUTH} component={Pages.AUTH} />
            <Route exact path={RoutePath.HOME}>
              <Redirect to={RoutePath.SITEMAP} />
            </Route>
            <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
            <Route path={RoutePath.SITEMAP} component={Pages.SITEMAP} />
            <Route path={RoutePath.LOCATIONS} component={Pages.LOCATIONS} />
            <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
            <Route path={RoutePath.SURVEYS} component={Pages.SURVEYS} />
            <Route path={RoutePath.REDIRECTION} component={Pages.REDIRECTION} />
            <Route path={RoutePath.USER} component={Pages.USERS} />
            <Route path={RoutePath.EDITORS} component={Pages.EDITORS} />
            <Route path={RoutePath.MEDIA} component={Pages.MEDIA} />
            <Route path={RoutePath.MEDIUM_MANAGEMENT} component={Pages.MEDIUMMANAGEMENTS} />
            <Route path={RoutePath.MEDIA_PROPERTY} component={Pages.MEDIAPROPERTIES} />
            <Route path={RoutePath.LOG} component={Pages.LOGS} />
            <Route path={RoutePath.AUDIT_LOG} component={Pages.AUTDITLOGS} />
            <Route path={RoutePath.AWARDS} component={Pages.AWARDS} />
            <Route path={RoutePath.AWARD_BUDGETS} component={Pages.AWARD_BUDGET} />
            <Route path={RoutePath.APP_SETTINGS_LIST} component={Pages.APPSETTINGS} />
            <Route path={RoutePath.AWARD_TRANSACTION_LIST} component={Pages.AWARDTRANSACTIONS} />
            <Route path="*" component={Pages.NOMATCH} />
          </Switch>
        case "Package123":
          return <Switch>
            <Route path={RoutePath.AUTH} component={Pages.AUTH} />
            <Route exact path={RoutePath.HOME}>
              <Redirect to={RoutePath.SITEMAP} />
            </Route>
            <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
            <Route path={RoutePath.SITEMAP} component={Pages.SITEMAP} />
            <Route path={RoutePath.LOCATIONS} component={Pages.LOCATIONS} />
            <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
            <Route path={RoutePath.SURVEYS} component={Pages.SURVEYS} />
            <Route path={RoutePath.REDIRECTION} component={Pages.REDIRECTION} />
            <Route path={RoutePath.USER} component={Pages.USERS} />
            <Route path={RoutePath.EDITORS} component={Pages.EDITORS} />
            <Route path={RoutePath.MEDIA} component={Pages.MEDIA} />
            <Route path={RoutePath.MEDIUM_MANAGEMENT} component={Pages.MEDIUMMANAGEMENTS} />
            <Route path={RoutePath.MEDIA_PROPERTY} component={Pages.MEDIAPROPERTIES} />
            <Route path={RoutePath.LOG} component={Pages.LOGS} />
            <Route path={RoutePath.AUDIT_LOG} component={Pages.AUTDITLOGS} />
            <Route path={RoutePath.NATIONAL_HOLIDAY} component={Pages.NATIONAL_HOLIDAYS} />
            <Route path={RoutePath.COMMENTS} component={Pages.COMMENTS} />
            <Route path={RoutePath.LEAVES} component={Pages.LEAVES} />
            <Route path={RoutePath.PENDING_LEAVE_LIST} component={Pages.PENDINGLEAVES} />
            <Route path={RoutePath.AWARDS} component={Pages.AWARDS} />
            <Route path={RoutePath.AWARD_BUDGETS} component={Pages.AWARD_BUDGET} />
            <Route path={RoutePath.AWARD_TRANSACTION_LIST} component={Pages.AWARDTRANSACTIONS} />
            <Route path={RoutePath.ADVANCE} component={Pages.ADVANCE} />
            <Route path={RoutePath.ADMINISTRATIVETASK_LIST} component={Pages.ADMINISTRATIVETASK} />
            <Route path={RoutePath.APP_SETTINGS_LIST} component={Pages.APPSETTINGS} />
            <Route path={RoutePath.PAYROLLS} component={Pages.PAYROLLS} />
            <Route path="*" component={Pages.NOMATCH} />
          </Switch>
        case "PackageEnerjisa":
          return <Switch>
            <Route path={RoutePath.AUTH} component={Pages.AUTH} />
            <Route exact path={RoutePath.HOME}>
              <Redirect to={RoutePath.SITEMAP} />
            </Route>
            <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
            <Route path={RoutePath.SITEMAP} component={Pages.SITEMAP} />
            <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
            <Route path={RoutePath.SURVEYS} component={Pages.SURVEYS} />
            <Route path={RoutePath.LOCATIONS} component={Pages.LOCATIONS} />
            <Route path={RoutePath.COMPANIES} component={Pages.COMPANY} />
            <Route path={RoutePath.MEDIA_PROPERTY} component={Pages.MEDIAPROPERTIES} />
            <Route path={RoutePath.AWARDS} component={Pages.AWARDS} />
            <Route path={RoutePath.AWARD_BUDGETS} component={Pages.AWARD_BUDGET} />
            <Route path={RoutePath.AWARD_TRANSACTION_LIST} component={Pages.AWARDTRANSACTIONS} />
            <Route path={RoutePath.USER} component={Pages.USERS} />
            <Route path={RoutePath.EDITORS} component={Pages.EDITORS} />
            <Route path={RoutePath.AUDIT_LOG} component={Pages.AUTDITLOGS} />
            <Route path={RoutePath.CMS} component={Pages.CMS} />
            <Route path={RoutePath.APP_SETTINGS_LIST} component={Pages.APPSETTINGS} />
            <Route path="*" component={Pages.NOMATCH} />
          </Switch>
        default:
          return <Switch>
            <Route path={RoutePath.AUTH} component={Pages.AUTH} />
            <Route path={RoutePath.WELCOMING_PAGE} component={Pages.WELCOMINGPAGE} />
            <Route path="*" component={Pages.NOMATCH} />
          </Switch>
          break;
      }
    }

  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <AuthGuard>
          <MasterPage>
            {
              returnFunc()
            }
          </MasterPage>
        </AuthGuard>
      </Suspense>
    </Router>
  )
}

export default App
