
const SettingsMenu = (): JSX.Element => {

  return (
    // <Dropdown overlay={menu} placement="bottomLeft" className={styles.settingsMr}>
    //   <div className="d-flex align-items-center">
    //     <Avatar icon={<SettingOutlined />} className={styles.settings} />
    //     <span>
    //       <DownOutlined />
    //     </span>
    //   </div>
    // </Dropdown>
    <></>
  )
}

export default SettingsMenu
