import { DownOutlined, SwapOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { useRecoilValue } from 'recoil'
import { currentUserState } from '../../store/user'
import styles from './PackageMenu.module.scss'
import API from '../../utils/api'
import { AxiosResponse } from 'axios'
import { BaseResponse } from '../../types/common/baseResponse'
import { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'

const PackageMenu = (): JSX.Element => {
  const currentUser = useRecoilValue(currentUserState)
  const [packages, setPackages] = useState<any>([])
  // const history = useHistory()
  const getPackages = () => {
    API.get('/Maintenance/GetAllPackages', {
    }).then(
      ({
        data,
      }: AxiosResponse<BaseResponse<any[]>>) => {
        setPackages(data?.result)
      }
    )
  }

  const setPackage = (packagee: any) => {
    API.post('/Maintenance/SetPackage', { packageName: packagee }).then(() => {

      window.location.href = '/';

    })
  }

  useEffect(() => {
    getPackages()
  }, [])

  const menu = (
    <Menu>
      {
        packages.map((packagee: any) => (
          <Menu.Item
            key={packagee}
            // danger
            icon={<SwapOutlined />}
            onClick={() => setPackage(packagee)}
          >
            {packagee}
          </Menu.Item>
        ))
      }
    </Menu>
  )
  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <div className="d-flex align-items-center">
        <span className={styles.userName}>
          {currentUser?.package} <DownOutlined />
        </span>
      </div>
    </Dropdown>
  )
}

export default PackageMenu
