import { Spin } from 'antd'
import styles from './Loading.module.scss'
const Loading = (): JSX.Element => {
  return (
    <div className={styles.layer}>
      <Spin />
    </div>
  )
}

export default Loading
